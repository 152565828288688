import React from 'react';
import Helmet from 'react-helmet';
import './GoogleMap.scss';

const GoogleMap = React.memo(() => {
  if (typeof window !== 'undefined') {
    window.initMap = function() {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 43.1023862, lng: -85.2243691 },
        zoom: 15,
      });
      const request = {
        placeId: 'ChIJP1VKSFqKGIgRLlGB3pU22P0',
        fields: ['name', 'formatted_address', 'geometry']
      };

      const infoWindow = new window.google.maps.InfoWindow();
      const service = new window.google.maps.places.PlacesService(map);

      service.getDetails(request, (place, status) => {
        if (status === 'OK') {
          const marker = new window.google.maps.Marker({
            map,
            position: place.geometry.location,
          });
          window.google.maps.event.addListener(marker, "click", function() {
            infoWindow.setContent(
              "<div><strong>" +
                place.name +
                "</strong><br>" +
                place.formatted_address +
                "</div>"
            );

            infoWindow.open(map, this);
          });
        }
      });
    }
  }
  const API = process.env.GATSBY_MAPS_API;
  return (
    <>
      <div id="map" className="iliad__google-map"/>
      {API && (
        <Helmet>
          <script src={`https://maps.googleapis.com/maps/api/js?key=${API}&callback=initMap&libraries=places`} async defer />
        </Helmet>
      )}
    </>
  )
});

export default GoogleMap;
