import React from 'react';
import { Grid, Cell } from '@trbl/react-css-grid';
import ShapedImage from '../ShapedImage';
import CTACard from '../CTACard';
import GoogleMap from '../GoogleMap';
import Card from '../Card';

const BTFAbout = React.memo(({ body, CTA, services, blockImage }) => {
  return (
    <>
      <section>
        <Grid>
          <Cell
            hStart={6}
            hSpan={10}
            hStartM={5}
            hSpanM={11}
            hStartS={2}
            hSpanS={14}
          >
            {body && (
              <div
                className="wmfab__markup-content"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
          </Cell>
        </Grid>
        <Grid>
          <Cell
            hStart={5}
            hSpan={9}
            hStartL={5}
            hSpanL={10}
            hStartM={4}
            hSpanM={12}
            hStartS={2}
            hSpanS={14}
          >
            <GoogleMap/>
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="about--featured"
        >
          <Cell
            hSpan={16}
            id="about--image-divider"
          >
            <ShapedImage
              color="green"
              backgroundImage={blockImage}
            />
          </Cell>
          <Cell
            hStart={2}
            hSpan={9}
            hStartL={1}
            hSpanL={10}
            hSpanM={14}
            hStartS={2}
            hSpanS={14}
            id="about--cta-card-container"
          >
            <CTACard
              title={CTA.title}
              description={CTA.description}
              image={CTA.photo}
              button={CTA.button}
            />
          </Cell>
        </Grid>
      </section>
      <aside>
        <Grid
          id="about--service-list"
        >
          <Cell
            hStart={3}
            hSpan={12}
            hStartL={3}
            hSpanL={12}
            hStartM={2}
            hSpanM={6}
            hStartS={3}
            hSpanS={12}
            id="about--service-list-copy"
          >
            <h2>What We Do Best</h2>
          </Cell>
          <Cell
            hStart={3}
            hSpan={12}
            hSpanL={12}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {services.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={4}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/services${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </aside>
    </>
  );
});

export default BTFAbout;
